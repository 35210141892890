import React from "react";
import Modal from "react-bootstrap/Modal";

const DeleteMachine = ({ showDeleteModal, onModalCancel, onDeleteClick }) => {
  return (
    <Modal centered show={showDeleteModal} onHide={onModalCancel}>
      <Modal.Header className="popupheader delete pt-4">Delete Machine</Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group pt-4 pb-5 m-0">
              <p className="popupheading delete p-0 m-0">{`Do you really want to delete selected Machine`}</p>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                <button className="cancel" onClick={onModalCancel}>
                  Cancel
                </button>
                <button className="save" onClick={onDeleteClick}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteMachine;
