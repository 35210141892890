import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { DeleteIcon, DownloadIcon, EyeIcon } from "../assets/Icons";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ExpandQR } from "./modals/ExpandQR";
import moment from "moment/moment";
import { getInitials } from "../utils/util";
import NoDataFound from "./NoDataFound";
import DeleteMachine from "./modals/DeleteMachine";
const Tables = ({ search, tableHeaders, data, fetchAllMachines }) => {
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [activeItems, setActiveItems] = useState({});
  const [showQR, setShowQR] = useState({
    machineName: null,
    qr: null,
    active: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMachineId, setDeleteMachineId] = useState("");

  const handleSwitch = async (uuid) => {
    const newState = !activeItems[uuid];
    try {
      await axios.put(
        `${configData.config.API_URL}/api/machine/update/${uuid}`,
        { isActive: newState },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setActiveItems((prev) => ({
        ...prev,
        [uuid]: newState,
      }));
    } catch (error) {
      console.error("Failed to update visibility", error);
    } finally {
      fetchAllMachines();
    }
  };

  const onDeleteClick = (uuid) => {
    setShowDeleteModal(true);
    setDeleteMachineId(uuid);
  };

  const onModalCancel = () => {
    setShowDeleteModal(false);
    setDeleteMachineId("");
  };

  const deleteHandler = async () => {
    try {
      if (deleteMachineId) {
        await axios.put(
          `${configData.config.API_URL}/api/machine/delete/${deleteMachineId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }
    } catch (error) {
      console.log(`Error in deleting machine ${error}`);
    } finally {
      setShowDeleteModal(false);
      fetchAllMachines();
    }
  };

  const handleDownloadQr = useCallback((qrCode) => {
    const link = document.createElement("a");
    link.href = qrCode;
    link.download = "QRCode.png";
    link.click();
  }, []);

  useEffect(() => {
    const initialActiveItems = data.reduce((acc, item) => {
      acc[item.uuid] = item.isActive;
      return acc;
    }, {});
    setActiveItems(initialActiveItems);
  }, [data]);

  let filterList = data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="table-responsive machinelist workflowtb mt-2">
      <table className="table align-middle table-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-center">S.No</th>
            <th>Machine Name</th>
            <th className="text-center">Created At</th>
            <th className="text-center">Updated At</th>
            <th className="text-center">Machine Id</th>
            <th className="text-center">Tags</th>
            <th className="text-center">Visibility</th>
            <th className="text-center">Actions</th>
            <th className="text-center">QR Code</th>
          </tr>
        </thead>

        {filterList.length > 0 ? (
          <tbody>
            {filterList.map((item, index) => {
              return (
                <tr key={item.uuid}>
                  <td className="text-center">{index + 1}</td>
                  <td className="machine-name">
                    <Link to={`/machine-info/${item.uuid}`}>
                      {item.thumbnail ? (
                        <img className="userimage-small" src={item.thumbnail} alt="" />
                      ) : (
                        <span className="initial-small">{getInitials(item.name)}</span>
                      )}
                      {item.name}
                    </Link>
                  </td>
                  <td className="text-center">{moment(item.createdAt).format("LLL")}</td>
                  <td className="text-center">{moment(item.updatedAt).format("LLL")}</td>
                  <td className="text-center">{item.data.machineId}</td>
                  <td className="text-center">
                    <div className="details-tags attable">
                      <span>
                        {item.tags.length
                          ? item.tags.map((e) => (
                              <p key={e.id} className="tag">
                                {e.name}
                              </p>
                            ))
                          : null}
                      </span>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="checkbox-apple">
                        <input
                          onChange={() => handleSwitch(item.uuid)}
                          className="yep"
                          id={`check-apple-${item.uuid}`}
                          type="checkbox"
                          checked={!!activeItems[item.uuid]}
                        />
                        <label htmlFor={`check-apple-${item.uuid}`}></label>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <Tooltip
                        title={
                          userData?.user?.uuid === item?.owner?.uuid ? "Delete Machine" : "Kindly contact the machine creator to delete this machine!"
                        }
                      >
                        <span
                          className={`delete-btn ${userData?.user?.uuid === item?.owner?.uuid ? "" : "disabled"}`}
                          onClick={() => {
                            if (userData?.user?.uuid === item?.owner?.uuid) {
                              onDeleteClick(item.uuid);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <div className="qr-code">
                        <img src={item.qr} alt="QR Code" />
                        <div className="qr-options">
                          <Tooltip title="Expand">
                            <span
                              onClick={() =>
                                setShowQR({ ...showQR, active: true, qr: item.qr, machineName: item.name })
                              }
                            >
                              <EyeIcon />
                            </span>
                          </Tooltip>

                          <Tooltip title="Download">
                            <span onClick={() => handleDownloadQr(item.qr)}>
                              <DownloadIcon />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <div className="d-flex align-items-center justify-content-center w-100">
            <NoDataFound
              nodatatext={"No Results Found"}
              dataInfo={"Please make sure written correctly or add a new machine"}
            />
          </div>
        )}
      </table>

      {showQR.active && <ExpandQR showQR={showQR} setShowQR={setShowQR} />}
      {showDeleteModal && (
        <DeleteMachine showDeleteModal={showDeleteModal} onModalCancel={onModalCancel} onDeleteClick={deleteHandler} />
      )}
    </div>
  );
};
export default Tables;
